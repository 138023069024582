@font-face {
    font-family: Mulish;
    src: url("/Fonts/Mulish-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Mulish;
    src: url("/Fonts/Mulish-Italic.woff2") format("woff2");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: Mulish;
    src: url("/Fonts/Mulish-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Mulish;
    src: url("/Fonts/Mulish-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Mulish;
    src: url("/Fonts/Mulish-BoldItalic.woff2") format("woff2");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
th,
td,
tr,
legend,
select,
button,
input[type="submit"] {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.maxText,
.saleprice,
.SubBask .PriceTotalBask,
.saleTime .s-producttext-withticket .s-largered,
#productDetails #infoTabs .infoTabPage a:hover,
h6,
.csHomeTable a:hover,
.SubTotal,
.csHomeTable li.header a:hover,
.BaskName:hover,
.saleTime .AltStratSellPrice.productHasRef,
.lillBasket #ulBag li .lineProblems span,
.lillBasket #ulBag li .maxText,
.yousave,
.nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
.saleTime .pdpPrice .productHasRef,
.saleTime #hsPrice,
.saleTime .PSProdPrice .PSSellPrice.productHasRef {
    color: #ed0000;
}

.discountText,
.preorder,
#FootcmdSearch,
.OrderComplete.Checkout22 a.button:hover,
.OrderComplete.Checkout22 a.button:focus {
    color: #000;
}

#dvCancelled,
#dvInProgress,
#dvNewOrder,
#dvRejected,
#dvShipped,
#dvSubmitted,
.Checkout22 .OrderCompleteRegistration input[type="submit"].ContinueOn:hover,
.Checkout22 .OrderCompleteRegistration input[type="submit"].ContinueOn:focus {
    color: #000 !important;
}

.personalisation {
    color: #000;
}

a,
a:active,
a:focus,
a:link,
a:visited,
.RightBox p,
.RightBox h3,
.RightBox,
.TopHeader p,
a:hover,
a p:hover {
    color: #000;
}

.RadTabStrip .rtsLevel .rtsIn:hover,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.LeftBarLinks #topLinkMenu ul.TopLinkMenu li,
.RadTabStrip .rtsLevel .rtsIn {
    color: #333 !important;
}

.Basket #BasketDiv table th,
.pppt,
.sizeDetail,
#productImages .imageControls .zoomControl,
#productImages .imageControls .spinControl,
.currentPage {
    color: #666;
}

.fromProductPrice {
    color: #888;
}

#securedByLogin span {
    color: #777 !important;
}

.originalprice,
input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled,
.BaskColr,
.BaskSize,
.s-smalltext,
.s-producttext-price .AdditonalPriceLabel {
    color: #888;
}

.Basket #BasketDiv .productcode span,
.ToplinksGroup .search input.watermarked {
    color: #636363;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
p,
label,
input,
optgroup,
select,
textarea,
.Basket h1,
.weWrap,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.sizeText,
.Profile h3,
.productFilterTitle,
.DontForgetText,
#productDetails #infoTabs .infoTabPage,
#productDetails #infoTabs .infoTabPage a,
#productDetails #infoTabs .infoTabPage h1,
#productDetails #infoTabs .infoTabPage h2,
#productDetails #infoTabs .infoTabPage h3,
#productImages .imageControls .zoomControl:hover,
#productImages .imageControls .spinControl:hover,
#productDetails #infoTabs .infoTabPage p,
.sizegroup h1,
.csHomeTable li.header a,
ins,
mark,
nav#topMenu > ul li.ClearBack > a,
nav#topMenu > ul li.ClearBack > a:hover,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter > a,
.PreDate,
.productcolour,
.productsize,
.categorycopyd4,
.MobFiltersText,
.sizeslink,
.ui-autocomplete > li > .ui-corner-all,
.MobSearchDropDown > li > a,
.OrderComplete.Checkout22 a.button.secondary {
    color: #000;
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a,
.entryPaging a {
    color: #333 !important;
}

#pagernav a,
.dbc,
#TooManyDailyOfferItemsError,
.jquery_tools_tooltip,
.ui-autocomplete li #ui-active-menuitem,
.sg_colHead,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.entryPaging a:hover,
#divBag.active .HeaderCheckoutLink,
.OrderComplete.Checkout22 a.button,
.OrderComplete.Checkout22 a.button.secondary:hover,
.OrderComplete.Checkout22 a.button.secondary:focus,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    color: #fff;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.entryPaging a:hover {
    color: #fff !important;
}

.dbc,
.contentwrap,
.header,
.productFilter-slider,
#topLinkMenu ul li a:hover {
    background-color: transparent;
}

div.NewMenuWrap {
    background: rgba(255, 255, 255, 0.95);
}

nav#topMenu > ul li.SaleTop,
#TooManyDailyOfferItemsError,
nav#topMenu > ul li.SaleTop:hover {
    background-color: #ed0000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 input,
.piActiveDot,
.sizeHeader,
.cs_sizeguide > tbody > tr.sg_colHead,
.mp-level,
.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a,
.sub-buttons input[type="button"]:hover,
.sdlProdList #mobFilterControls li .textIconWrap,
.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    background-color: #000 !important;
    border: 1px solid #000 !important;
}

.Login .ImgButWrap a#LoginButton,
.Login .ImgButWrap a.dnnPrimaryAction {
    border: none !important;
}

.Login .loginContainer .ImgButWrap a:hover::after,
.Login .loginContainer .ImgButWrap a:focus::after,
.Login .submitWrap .ImgButWrap a:hover::after,
.Login .submitWrap .ImgButWrap a:focus::after,
.BasketNew .primary2.ImgButWrap a:hover::after,
.BasketNew .primary2.ImgButWrap a:focus::after,
.submitBut .ImgButWrap a:hover::after,
.submitBut .ImgButWrap a:focus::after {
    background-position: -164px -158px;
}

.ImgButWrap a:hover,
.ImgButWrap input:hover,
.ImgButWrap a:focus,
a.prc_commentpostingpanelcommentsectionlink:hover,
.Basket #BasketDiv .UpdateQuant:hover,
.Login .loginContainer .ImgButWrap:hover,
.Profile a.dnnPrimaryAction:hover,
#addressbook-edit-dialog a.dnnPrimaryAction:hover,
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
.buttonAction:hover,
.basketLink .langlogin a.login:hover,
.s-basket-button-right input:hover,
.OrderGroup .OrderColC .ButtonOrder a:hover,
.OrderColC .TrackOrder a:hover,
.sdlProdList #mobFilterControls li .textIconWrap:hover {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #000 !important;
}

.addToBasketContainer .ImgButWrap a,
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a,
.BasketNew a.ContinueOn,
#divBag.active .HeaderCheckoutLink,
.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    background-color: #000 !important;
}

.addToBasketContainer .ImgButWrap a:hover,
.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
#aViewBag:hover,
.PersVouchBasketContainer .ImgButWrap a:hover,
.CheckWrap .ImgButWrap a:hover,
.CheckWrap a.ContinueOn:hover,
.CheckWrap .ContinueOn a:hover,
.CheckWrap input.ContinueOn:hover,
.CareersBrand .vacancy-search-submit a:hover,
.CareersBrand .vacancy-more-info-btn a:hover,
.BasketNew a.ContinueOn:hover,
.BasketNew a.ContinueOn:focus,
#divBag.active .HeaderCheckoutLink:hover,
#divBag.active .HeaderCheckoutLink:focus,
.OrderCompleteRegistration .ContinueOn:hover,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction:hover {
    background-color: #fff !important;
    color: #000;
}

.BodyWrap,
#BodyWrap,
.DontForgetText,
body,
.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a,
.HeaderWrap,
#OrderHistoryPeriod select:focus,
#productDetails #infoTabs .infoTabPageContainer,
#productDetails #infoTabs .multiPage,
.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripBottom_Vista .rtsLevel .rtsLink,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn:hover,
#divBagItems,
#divEmptyErrorMessage,
#divAjaxLoaderImage,
#ulBag li,
.ASCdelivery2 .FullAdvert img,
.FourHovers ul li > div,
#mobControlBar {
    background-color: #fff;
}

.Checkout .ContentWrapper {
    background-color: #f8f8f8;
}

.RadTabStrip .rtsLevel .rtsIn:hover {
    background-color: #f1f1f1;
}

.currentPage,
.greyBoxBrand,
.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a,
.OrderComplete.Checkout22 a.button:not(.secondary):hover,
.OrderComplete.Checkout22 a.button:not(.secondary):focus,
.OrderComplete.Checkout22 a.button.secondary,
.Checkout22 .OrderCompleteRegistration .ContinueOn:hover,
.Checkout22 .OrderCompleteRegistration .ContinueOn:focus {
    background: #fff !important;
}

.jquery_tools_tooltip {
    background-color: #333;
}

#Storetext {
    background: rgba(51, 51, 51, 0.5);
}

.sub-buttons input[type="button"],
.OrderComplete.Checkout22 a.button.secondary:hover,
.OrderComplete.Checkout22 a.button.secondary:focus {
    background-color: #000 !important;
}

.advert .pagination,
.Home .advert .swiper-container,
.Vieworders {
    background-color: #ccc;
}

.sizegroup h1,
.c_BlackTitle h3,
.csHomeTable li.header {
    background-color: #eee;
}

#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
#OrderHistoryPeriod select {
    background-color: #f1f1f1;
}

.byPostGroup {
    background-color: #f7f7f7;
}

.entryPaging a:hover {
    background-color: #666 !important;
}

ins {
    background: #ff9;
}

mark {
    background: #ff0;
}

nav#topMenu > ul li.root.ClearBack {
    background-color: #f3d600;
}

nav#topMenu > ul li.root.ClearBack:hover {
    background-color: #e5ca00 !important;
}

.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    border-color: #000;
}

.OrderComplete.Checkout22 a.button.secondary {
    border-color: #000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a {
    background-image: none;
    border: 0;
    border-radius: 0;
    filter: none;
    text-shadow: none;
}

.groupquestions li {
    background: transparent url("/images/core/diam4vr.png") no-repeat scroll left center;
}

#CheckoutSpaRoot button,
#CheckoutSpaRoot .inactiveSubmit button:hover {
    background-color: #000 !important;
    border-radius: 0;
}

#CheckoutSpaRoot .inactiveSubmit button:hover {
    color: #fff !important;
}

#CheckoutSpaRoot button:hover,
#CheckoutSpaRoot button:focus {
    background-color: #fff !important;
    color: #000 !important;
}

ul.toptabs {
    width: 100%;
    margin: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a {
    background-color: #000;
}

.activeSection .sectionGroup h1::before {
    background-color: #35a9b6;
}

.blkBldTxt,
.redBldTxt {
    font-weight: bold;
}

.redBldTxt {
    color: #ed0000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aCheckout,
#aViewBag,
.PersVouchBasketContainer .ImgButWrap a,
.ToplinksGroup .search #cmdSearch,
#divBag,
#divBag.active .HeaderCheckoutLink,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a,
#productDetails .personalisationTitlePanel,
.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    transition: all 0.3s ease-in;
}

.PersonaliseSpace .PersVouchBasketContainer .ImgButWrap a {
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: bold;
    padding: 20px 5px;
    text-transform: uppercase;
}

#hotspotModal h2 {
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: normal;
}

#hsPriceWrapper {
    margin-bottom: 15px;
}

#hsPrice {
    font-size: 1.2em;
    display: inline-block;
    padding-right: 10px;
    line-height: 1.2em;
    font-weight: bold;
    letter-spacing: 0.05em;
    float: none;
}

#hsAdditionalPriceLabel {
    display: inline-block;
    font-size: 1.1em;
    padding: 0 5px 0 0;
    line-height: 1.3em;
    float: none;
    color: #888;
}

#hsRefPrice {
    display: inline-block;
    font-size: 1.1em;
    padding-top: 0;
    line-height: 1.3em;
    float: none;
    color: #888;
}

.hsColourDesc .ColourLabel,
.hsSizeLabel {
    letter-spacing: 0.05em;
    font-size: 1.2em;
    line-height: 1.2em;
}

.hsColourDesc .ColourLabel::after,
.hsSizeLabel::after {
    content: ":";
}

.hsColourDesc .ColourDesc {
    font-weight: normal;
    font-style: italic;
    font-size: 1.2em;
    line-height: 1.2em;
}

#hsAddToBagContainer a {
    padding: 20px 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
    display: block;
}

.summaryheader .summaryTitleText {
    font-size: 18px;
    letter-spacing: 0.66px;
    text-transform: uppercase;
}

.summaryList .summaryListTitle,
.summaryList .summaryListInfo,
.summaryList .summaryListInfoPrice,
.summaryTotal div,
.delopeningText,
.summaryListInfoPriSell,
.summaryListInfoOrigPriSell,
.form-group .labelGroup label,
.welcomeSection input,
.unexpected-error,
.selectionOptions,
.delcountryEdit a,
.giftcardSection .selectionDescription h3,
.promocodeSection .selectionDescription h3,
.deliveryPrice div,
.inputTickGroup input,
.contactnumberInfo,
.errorMessage,
.changeLink,
.errormessage p,
.estimatedDateGroup,
#footerStepMsg,
.copyrightTextMob,
.estimatedDateGroup .estimatedDateTitle {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.summaryPrice,
.paymentSection .progressTitleSub {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.sectionGroup h1,
.formCompleteCTA button,
.summaryList .summaryListBrand,
.summaryheader .summaryTitleText,
.selectionDescription h3,
.selectionOptions .estimatedDateGroup .estimatedDateValue,
.paymentSection .progressTitleTop {
    font-family: Mulish, Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.formCompleteCTA button {
    letter-spacing: 0.1em;
}

.summaryListInfoOrigPriSell {
    font-weight: normal;
}

.summaryListInfo {
    font-size: 11px;
}

.summaryListText {
    width: calc(100% - 150px);
}

.selectionOptions .selectionDescription .SpendVoucher {
    padding: 5px 15px 0 0;
}

.selectionOptions .deliveryDesc + .estimatedDateGroup {
    list-style: none;
}

.estimatedDateGroup .estimatedDateValue {
    font-weight: bold;
}

.headerSummaryContainer {
    background-color: #000;
}
